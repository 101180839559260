import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Form, Button, Container, Row, Col, InputGroup } from 'react-bootstrap'
import { SketchPicker } from 'react-color'
import { getTenantData } from '../../services/get-tenant-data'
import { updateTenantData } from '../../services/update-tenant-data'
import { createTenantData } from '../../services/create-tenant-data'

const crypto = require('crypto')

const validationSchema = yup.object().shape({
  uiStrings: yup
    .string('Enter UI strings')
    .required('UI Strings are required')
    .min(8, 'UI Strings should be of minimum 8 characters long'),
  tenantName: yup
    .string('Enter tenant name')
    .required('Tenant name is required')
    .min(3, 'Tenant name should be of minimum 3 characters length'),
  color: yup.string('Enter your color').required('Color is required'),
  fileName: yup.string('Path to a file'),
  onboardingOrder: yup.string('Onboarding order'),
})

const schema = yup.object().shape({
  tenantName: yup
    .string()
    .required('Tenant name is required')
    .min(3, 'Tenant name should be of minimum 3 characters long'),
  primaryColor: yup.string().required(),
  username: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: yup.string().required(),
  terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
})

// And now we can use these
export const TenantForm = () => {
  const [primaryColor, setPrimaryColor] = useState('#0052CC')
  const [textColor, setTextColor] = useState('#000000')
  const [shadingColor, setShadingColor] = useState('#D3D3D3')
  const [backgroundColor, setBackgroundColor] = useState('#FFDEAD')
  const [tenantId, setTenantId] = useState('tenant ID')
  const [dataOnly, setDataOnly] = useState(false)
  const [monthly, setMonthly] = useState(false)
  const [initialValues, setInitialValues] = useState({
    tenantName: '',
    primaryColor: '#0052CC',
    textColor: '#000000',
    shadingColor: '#D3D3D3',
    backgroundColor: '#FFDEAD',
    username: '',
    city: '',
    state: '',
    zip: '',
    terms: false,
  })

  function getData() {
    getTenantData().then((data) => {
      setInitialValues(data)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  function handleTenantId(e) {
    console.log(e)
    if (e && e.target.value) {
      setTenantId(crypto.createHash('md5').update(e.target.value).digest('hex'))
    }
  }

  function save(payload) {
    updateTenantData(payload)
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={save}
      enableReinitialize={true}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Container className="form-padding">
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row className="align-items-start">
              <Form.Group as={Col} md="4" controlId="validationFormik01">
                <Form.Label>Tenant name</Form.Label>
                <Form.Control
                  type="text"
                  name="tenantName"
                  value={values.tenantName}
                  onChange={(e) => {
                    handleTenantId(e)
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.tenantName && !!errors.tenantName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.tenantName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="8" controlId="validationFormikUsername">
                <Form.Label>TenantID</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="tenant id hash"
                  aria-describedby="inputGroupPrepend"
                  name="tenantId"
                  value={tenantId}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="example">
                <div
                  style={{
                    borderRadius: '3px',
                    padding: '10px',
                    backgroundColor: backgroundColor,
                    boxShadow: '5px 5px 5px 5px' + shadingColor,
                  }}
                >
                  <div
                    style={{
                      borderRadius: '3px',
                      padding: '5px 20px',
                      marginBottom: '10px',
                      color: 'white',
                      backgroundColor: primaryColor,
                    }}
                  >
                    Primary color
                  </div>

                  <span style={{ paddingLeft: '30px', color: textColor }}>
                    EXAMPLE TEXT
                  </span>
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="3" controlId="primaryColor">
                <Form.Label>Primary color</Form.Label>
                <SketchPicker
                  name="primaryColor"
                  color={primaryColor}
                  onChangeComplete={(e) => {
                    handleChange(e.hex)
                  }}
                  onChange={(e) => {
                    setPrimaryColor(e.hex)
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="textColor">
                <Form.Label>Text color</Form.Label>
                <SketchPicker
                  name="textColor"
                  color={textColor}
                  onChangeComplete={(e) => {
                    handleChange(e.hex)
                  }}
                  onChange={(e) => {
                    setTextColor(e.hex)
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="shadingColor">
                <Form.Label>Shading color</Form.Label>
                <SketchPicker
                  name="shadingColor"
                  color={shadingColor}
                  onChangeComplete={(e) => {
                    handleChange(e.hex)
                  }}
                  onChange={(e) => {
                    setShadingColor(e.hex)
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} md="3" controlId="backgroundColor">
                <Form.Label>Background color</Form.Label>
                <SketchPicker
                  name="backgroundColor"
                  color={backgroundColor}
                  onChangeComplete={(e) => {
                    handleChange(e.hex)
                  }}
                  onChange={(e) => {
                    setBackgroundColor(e.hex)
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationFormik01">
                <Form.Label>Payment methods</Form.Label>
                <Form.Check
                  required
                  name="twint"
                  label="TWINT"
                  onChange={handleChange}
                  // isInvalid={!!errors.terms}
                  // feedback={errors.terms}
                  id="validationFormik0"
                />
                <Form.Check
                  required
                  name="visa"
                  label="VISA"
                  onChange={handleChange}
                  // isInvalid={!!errors.terms}
                  // feedback={errors.terms}
                  id="validationFormik0"
                />
                <Form.Check
                  required
                  name="paypal"
                  label="PAYPAL"
                  onChange={handleChange}
                  // isInvalid={!!errors.terms}
                  // feedback={errors.terms}
                  id="validationFormik0"
                />
                <Form.Check
                  required
                  name="mastercard"
                  label="MASTERCARD"
                  onChange={handleChange}
                  // isInvalid={!!errors.terms}
                  // feedback={errors.terms}
                  id="validationFormik0"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.paymentMethods}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group>
                <Form.Label>Upload icons</Form.Label>
                <Form.File id="exampleFormControlFile1" multiple />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group>
                <Form.Label>Upload translation strings (CSV)</Form.Label>
                <Form.File id="exampleFormControlFile1" />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6" controlId="validationFormik01">
                <Form.Label>Payment plan</Form.Label>
                <Form.Check
                  required
                  name="data-only"
                  label="Data Only"
                  onChange={(e) => {
                    setDataOnly(e.target.checked)
                  }}
                  // isInvalid={!!errors.terms}
                  // feedback={errors.terms}
                  id="validationFormik0"
                />
                <Form.Check
                  required
                  name="monthly"
                  label="Monthly"
                  onChange={(e) => {
                    setMonthly(e.target.checked)
                  }}
                  // isInvalid={!!errors.terms}
                  // feedback={errors.terms}
                  id="validationFormik0"
                />
              </Form.Group>
            </Form.Row>
            {dataOnly && (
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="aowkdoawkdo">
                  <Form.Label>Data Only Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    // value={values.tenantName}
                    // onChange={(e) => {
                    //   handleTenantId(e)
                    //   handleChange(e)
                    // }}
                    // onBlur={handleBlur}
                    // isInvalid={touched.tenantName && !!errors.tenantName}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationFormik01">
                  <Form.Label>Data Only Currency</Form.Label>
                  <Form.Control as="select" custom>
                    <option>CHF</option>
                    <option>EUR</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.tenantName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            )}
            {monthly && (
              <Form.Row>
                <Form.Group as={Col} md="4" controlId="aowkdoawkdo">
                  <Form.Label>Monthly Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    // value={values.tenantName}
                    // onChange={(e) => {
                    //   handleTenantId(e)
                    //   handleChange(e)
                    // }}
                    // onBlur={handleBlur}
                    // isInvalid={touched.tenantName && !!errors.tenantName}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationFormik01">
                  <Form.Label>Monthly Currency</Form.Label>
                  <Form.Control as="select" custom>
                    <option>CHF</option>
                    <option>EUR</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.tenantName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            )}

            <Button type="submit">Submit form</Button>
          </Form>
        </Container>
      )}
    </Formik>
  )
}
