import { docClient } from './dynamoDB-client'

export async function getTenantData() {
  var params = {
    TableName: 'TenantConfiguration',
    KeyConditionExpression: '#user_id = :user_id',
    ExpressionAttributeNames: {
      '#user_id': 'user_id',
    },
    ExpressionAttributeValues: {
      ':user_id': (await global.exposed_auth.currentUserInfo()).id,
    },
  }

  let result

  await docClient
    .query(params, function (err, data) {
      if (err) {
        console.error('Unable to query. Error:', JSON.stringify(err, null, 2))
        result = false
      } else {
        console.log('Query succeeded.')
        result = data.Items
      }
    })
    .promise()

  return result
}
