import React from 'react'
import PropTypes from 'prop-types'
import styles from './Footer.module.scss'

const Footer = () => (
  <div className={styles.Footer} data-testid="Footer">
    <footer>
      <div className="footer-pad">
        <div className="footer-left">
          <span>
            Moflix - &copy; {new Date().getFullYear()} All Rights Reserved
          </span>
        </div>
        <div className="footer-right">
          <a href="https://www.moflixgroup.com/contact" target="_blank">
            Need help?
          </a>
          <a href="https://www.moflixgroup.com/" target="_blank">
            Moflix
          </a>
        </div>
      </div>
    </footer>
  </div>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
