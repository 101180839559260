import { docClient } from './dynamoDB-client'

function createUpdateExpression(tenantData) {
  return (
    'set' +
    Object.keys(tenantData)
      .map((key) => key + '= :' + key)
      .join(', ')
  )
}

function createExpressionAttributeValues(tenantData) {
  const expressionAttributeValues = {}
  for (let key in tenantData) {
    expressionAttributeValues[':' + key] = tenantData[key]
  }

  return expressionAttributeValues
}

export async function updateTenantData(tenantData) {
  var params = {
    TableName: 'TenantConfiguration',
    Key: {
      user_id: (await global.exposed_auth.currentUserInfo()).id,
    },
    UpdateExpression: createUpdateExpression(tenantData),
    ExpressionAttributeValues: createExpressionAttributeValues(tenantData),
    ReturnValues: 'UPDATED_NEW',
  }

  console.log('Updating the item...')

  let result

  await docClient
    .update(params, function (err, data) {
      if (err) {
        console.error(
          'Unable to update item. Error JSON:',
          JSON.stringify(err, null, 2)
        )
        result = false
      } else {
        console.log('UpdateItem succeeded:', JSON.stringify(data, null, 2))
        result = true
      }
    })
    .promise()

  return result
}
