import { API } from 'aws-amplify'
import { Authenticator } from 'aws-amplify-react'
import { Auth } from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css'

/* https://dev.to/kylegalbraith/customizing-the-aws-amplify-authentication-ui-with-your-own-react-components-5fjb */
import '@aws-amplify/ui/dist/style.css'

import React, { useEffect, useState } from 'react'

import './App.css'
import './css/modum.scss'
import { TenantForm } from './components/Form/Form'
import Footer from './components/Footer/Footer'

function App() {
  const [isAuthanticated, setIsAuthanticated] = useState(false)

  var callOnce = false
  function respondToAuthStateChange(state, cognitoUser) {
    if (state === 'signedIn' && cognitoUser) {
      //called 3 times, need to find better way
      if (!callOnce) {
        callOnce = true
        setIsAuthanticated(true)
      }
    } else {
      setIsAuthanticated(false)
    }
  }

  useEffect(() => {
    window.exposed_api = API
    window.exposed_auth = Auth
  }, [])

  return (
    <div className="App">
      <header className="top-nav">
        <a href="/" className="logo-bw">
          Home
        </a>
        {isAuthanticated && (
          <a
            href="/"
            className="nav-link signout"
            onClick={() => Auth.signOut()}
          >
            Sign Out
          </a>
        )}
      </header>

      <div className="header-spacing"></div>

      {!isAuthanticated && (
        <div className="authenticator-wrap">
          <div className="about-app">
            {/* <h1>Moflix POC Configurator</h1> */}
            <p className="info">
              Sign in using your Moflix POC Configurator App credentials to
              access your account, and to update tenant's configuration.
            </p>
          </div>

          <Authenticator
            onStateChange={respondToAuthStateChange}
          ></Authenticator>
        </div>
      )}

      {/* {isAuthanticated && isEligible == false && <NotEligible></NotEligible>} */}

      {isAuthanticated && <TenantForm></TenantForm>}

      <Footer />
    </div>
  )
}

export default App
